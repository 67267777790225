@use '@sparbanken-syd/sparbanken-syd-theme/theme' as spb;
@use '@angular/material' as mat;
@use 'variables' as var;
@include spb.set-typography;


html {
  height: 100%;
  margin: 0;
  width: 100%;
}

textarea {
  line-height: 28px !important;
}


body {
  font-family: 'Titillium Web', sans-serif;
  height: 100%;
  margin: 0 auto;
}

/* General styling that applies to many pages */

/* Plain is just a holder for content on pages w/o hero images. */
.spb-plain-content {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  max-width: var.$spb-max-width;
  width: 100%;
}

@media screen and (width >= 400px) {
  .spb-plain-content > * {
    padding-left: 45px;
    padding-right: 33%;
  }
}

@media screen and (max-width: var.$spb-max-width) and (min-width: var.$spb-small) {
  .spb-plain-content > * {
    padding-left: 45px;
    padding-right: 33%;
  }
}

@media screen and (width <= 1215px) and (width >= 400px) {
  .spb-plain-content > * {
    padding-left: 65px;
    padding-right: 20%;
  }
}


@media screen and (width <= 400px) {
  .spb-plain-content > * {
    padding-left: 15px;
    padding-right: 15px;
  }
}


/* A yellow button with some text in it. Tries to be centered in a flex layout */
.spb-yellow-button {
  align-self: center;
  background-color: var.$spb-yellow;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  padding: 14px 30px;
  text-transform: uppercase;
  margin-bottom: 12px;
}

/* use this to grow */
.filler {
  display: flex;
  flex-grow: 1;
}

/* Wrap headers, footers and content in this one */
.spb-holder {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/**
 Small tweak on form fields to make them behave
 */
.sbp-standard-full-width {
  width: 66%;
}

@media screen and (max-width: var.$spb-small) {
  .spb-standard-full-width {
    padding: initial;
    width: 100%;
  }
}

/**
  Checkbox labels do not line break and have to be here ... 2019-08-08 /Daniel
 */
.mat-checkbox-layout {
  white-space: normal !important;
}

.mat-radio-label {
  white-space: normal !important;
}

.mat-checkbox-inner-container {
  margin: 4px 8px auto 0 !important;
}

h1,
h2,
h3 {
  margin-bottom: 7px !important;
  margin-top: 15px !important;
}

h4 {
  margin-bottom: 7px !important;
  margin-top: 20px !important;
}

a {
  color: #232323;
}

/*
Header in tables
*/
th {
  color: rgb(0 0 0 / 54%) !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  white-space: nowrap !important;
}

/**
Hide scrollbar in table
 */
.mat-mdc-tab-body-content {
  overflow: hidden !important;
}

/*
Not very nice I know!!! I'm trying to make sure the text fills the select-box. Ask @Daniel for a better solution
 */
.mat-mdc-select-value-text {
  white-space: initial !important;
}

/**
All icons must me centred instead of appearing a bit to the left in their "boxes"
 */
button {
  .mat-icon {
    margin: 0 !important;
  }
}
